@font-face {
  font-family: 'Pokemon Edit';
  src: url('/public/fonts/PokemonEdit.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GBA Edit';
  src: url('/public/fonts/GBAEdit.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}